<template>
  <v-footer class="mt-0 pt-0 px-0 d-flex flex-column white">
    <div
      id="social-network-wrapper"
      class="primary d-flex justify-center align-center w-100 pa-2"
    >
      <span class="text-body-1 font-weight-bold mr-2"
        >{{ $t("footer.followUs") }}:</span
      >
      <div
        v-for="item in socialLinks"
        :key="item.templateNavItemId"
        class="d-flex align-center"
      >
        <a :href="item.itemUrl" target="_blank" :title="item.name">
          <v-icon class="mx-2">{{ `$${item.iconSource}` }}</v-icon>
        </a>
      </div>
    </div>
    <v-container>
      <v-row no-gutters>
        <v-col cols="12" md="9">
          <v-row
            no-gutters
            v-if="links && $vuetify.breakpoint.mdAndUp"
            justify="space-between"
          >
            <!-- custom layout for column with "socials" CSS class, showing button for app download -->
            <!-- "socials" column not rendered on mobile app -->
            <v-col
              v-for="link in links"
              :key="link.templateNavItemId"
              cols="12"
              sm="6"
              md="3"
              class="d-flex flex-column justify-start align-center align-md-start pr-md-2"
            >
              <a
                v-if="link.itemUrl"
                :href="link.itemUrl"
                :title="link.name"
                class="default--text text-decoration-none"
              >
                <span
                  class="text-center text-h2 font-weight-bold text-sm-left my-0"
                >
                  <v-icon v-if="link.iconSource">{{ link.iconSource }}</v-icon>
                  {{ link.name }}
                </span>
              </a>
              <span
                v-else
                class="text-center text-h2 font-weight-bold text-sm-left my-0"
              >
                {{ link.name }}
              </span>
              <a
                v-for="child in link.templateNavItems"
                :key="child.templateNavItemId"
                :href="child.itemUrl"
                class="default--text text-decoration-none pa-1"
                :class="child.classStyle"
                :target="child.target || '_self'"
                @click="clickLink"
              >
                {{ child.name }}
              </a>
            </v-col>
          </v-row>
          <div v-else-if="links && $vuetify.breakpoint.smAndDown">
            <v-expansion-panels accordion flat tile :focusable="selectedTab">
              <v-expansion-panel v-for="(link, index) in links" :key="index">
                <v-expansion-panel-header>
                  <template v-slot:actions>
                    <v-icon>$chevronDown</v-icon>
                  </template>
                  {{ link.name }}
                </v-expansion-panel-header>
                <v-expansion-panel-content class="mt-2">
                  <a
                    v-for="child in link.templateNavItems"
                    :key="child.ID"
                    :href="child.itemUrl"
                    class="d-block default--text text-decoration-none pa-1"
                    :class="child.classStyle"
                    :target="child.target"
                    @click="clickLink"
                  >
                    <span :class="child.classStyle">{{ child.name }}</span>
                  </a>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </div>
        </v-col>
        <v-col cols="12" md="3">
          <div class="pa-3 d-flex flex-column  align-center align-md-start">
            <h4 class="text-center font-weight-bold text-sm-left">
              {{ $t("footer.downloadApp") }}
            </h4>
            <a
              href="https://apps.apple.com/it/app/al%C3%AC/id1188219516"
              target="_blank"
            >
              <img
                class="d-block"
                src="/img_layout/footer/app-store.png"
                alt="Immagine app store"
              />
            </a>
            <a
              href="https://play.google.com/store/apps/details?id=it.alisupermercati.app"
              target="_blank"
            >
              <img
                class="d-block my-2"
                src="/img_layout/footer/google-play.png"
                alt="Immagine google play"
              />
            </a>
          </div>
        </v-col>
      </v-row>
    </v-container>
    <v-divider class="primary w-100" />
    <v-container :fluid="$vuetify.breakpoint.xs">
      <v-layout
        class="align-start align-sm-center d-inline-flex"
        :column="$vuetify.breakpoint.xs"
      >
        <v-img src="/logo/AliAliper_logo.svg" class="mb-3 mb-sm-0" />
        <div class="text-caption mx-3 mx-sm-10">
          <span v-html="$t('footer.copyright')"></span> - V: {{ version }}
        </div>
      </v-layout>
      <RecaptchaDisclaimer @click="clicked" />
    </v-container>
  </v-footer>
</template>
<style scoped lang="scss">
.container {
  background-color: $white;
}
.v-expansion-panel-header {
  border-bottom: 1px solid $border-color !important;
}
@media print {
  .profile-container .booker-awards-container,
  footer .container,
  footer,
  .voucher-close,
  #social-network-wrapper > *,
  .v-divider {
    display: none !important;
  }
}
</style>

<script>
import clickHandler from "@/commons/mixins/clickHandler";
import RecaptchaDisclaimer from "~/components/RecaptchaDisclaimer.vue";
export default {
  name: "Footer",
  mixins: [clickHandler],
  components: { RecaptchaDisclaimer },
  props: {
    links: { type: Array, required: true },
    socialLinks: { type: Array, required: true }
  },
  data() {
    return {
      selectedTab: null
    };
  },
  computed: {
    version() {
      return global.config.version;
    }
  },
  methods: {
    clickLink: function(event) {
      if (event.target.className.includes("cookie-widget-trigger")) {
        if (window.Cookiebot) {
          window.Cookiebot.show();
        }
      } else {
        this.clicked(event);
      }
    }
  }
};
</script>
